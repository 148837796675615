import { createApp } from 'vue'
import { createStore } from 'vuex'
import VuexPersistence from "vuex-persistedstate";

const app = createApp()


export const store = createStore({

    state() {
        return{
            user: null,
            msgModal:'',
            open_load_modal: [],
        }
    },


    getters: {
        USER: state => {
            return state.user
        },
        ACTIVE_LOAD_MODAL: (state) => (state.open_load_modal.length > 0 ? state.open_load_modal[0] : null),
        ALL_OPEN: (state) => state.open_load_modal,
        IS_ACTIVE_MODAL_LOAD: (state) => state.open_load_modal.filter((e) => e === 'loadModal'),
        MSG_MODAL:  (state) => state.msgModal,
    },

    mutations: {
        SET_USER: (state, payload) => {
            state.user = payload
        },
        DELETE_USER: (state, payload) => {
            state.user = payload;
        },
        OPEN_LOAD_MODAL: (state, payload) => state.open_load_modal.unshift(payload),
        CLOSE_LOAD_MODAL: (state, payload) => (state.open_load_modal = state.open_load_modal.filter((e) => e !== payload)),
        OPEN_MODAL_MSG: (state, payload) => {
            state.open_load_modal.unshift(payload[0])
            state.msgModal = payload[1]
        }
    },

    actions: {
        DELETE_USER: (context, payload) => {
            context.commit('DELETE_USER', null)
        },
        SET_USER: async (context, payload) => {
            context.commit('SET_USER', payload)
        },
        OPEN_LOAD_MODAL: ({ commit }, payload) => commit('OPEN_LOAD_MODAL', payload),
        CLOSE_LOAD_MODAL: ({ commit }, payload) => commit('CLOSE_LOAD_MODAL', payload),
        OPEN_MODAL_MSG: ({ commit }, payload) => commit('OPEN_MODAL_MSG', ['modalMsg', ...payload]),
        // OPEN_MODAL_MSG_SUCCESS: ({ commit }, payload) => commit('OPEN_MODAL_MSG', ['modalMsg', true, payload]),
        // OPEN_MODAL_MSG_ERROR: ({ commit }, payload) => commit('OPEN_MODAL_MSG', ['modalMsg', false, payload]),
    },

    plugins: [
        VuexPersistence({
            storage: window.localStorage,
            key:'skopos_store'
        })
    ],
})
