<template>
    <router-view/>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
    export default {
        name: 'App',

        created(){
            const firebaseConfig = {
                apiKey: "AIzaSyAtuuid9Qq2On3_xHuyIsw220fF-tkd3kU",
                authDomain: "site-skopos.firebaseapp.com",
                projectId: "site-skopos",
                storageBucket: "site-skopos.appspot.com",
                messagingSenderId: "881002068215",
                appId: "1:881002068215:web:68174a8c8686179d93fbf2",
                measurementId: "G-X5LTVSMRVF"
            };

            // Initialize Firebase
            const app = initializeApp(firebaseConfig);
            const analytics = getAnalytics(app);
        }
    }
</script>

<style lang="scss">
    @import "bootstrap/dist/css/bootstrap.css";
    @import "bootstrap-vue/dist/bootstrap-vue.css";
    /* @import 'assets/scss/fonts';
     */
    /* @import '../assets/scss/global'; */
</style>
